@media all and (max-width: 319px) { 
    
    .trainingschedule_img{
        width: 30vw;
          }
          .header_training_schedule>h2{
              font-size: 3.5em;
              margin-bottom: 2vh;
      
          }
          .img-fluid{
            width: 30vw;
             
          }
          .card-box{
              flex: 1;
          }
        .container{
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          overflow: hidden;
        }
          .card-box h3 a {
          
              font-size: 1.5em;
          
          }
          .text-secondary{
             font-size: 1.2em;
          }
          .place{
            font-size: 0.8em;
        }/* smartphones, iPhone, portrait 480x320 phones */ }
@media all and (max-width: 639px) and (min-width: 320px)  { 
    
    .trainingschedule_img{
        width: 78vw;
          }
          .header_training_schedule>h2{
            font-size: 2.8em;
            margin-bottom: 2vh;
      
          }
          .img-fluid{
            width: 20vw;
             
          }
          .card-box{
              flex: 1;
          }
        .container{
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          overflow: hidden;
        }
          .card-box h3 a {
          
              font-size: 1.5em;
          
          }
          .text-secondary{
             font-size: 1.2em;
          }
          .place{
            font-size: 0.8em;
        }/* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ }
@media all and (max-width: 799px) and (min-width: 640px) { 
    
    
    .trainingschedule_img{
        width: 50vw;
          }
          .header_training_schedule>h2{
              font-size: 3.5em;
              margin-bottom: 2vh;
      
          }
          .img-fluid{
              width: 15vw;
             
          }
          .card-box{
              flex: 1;
          }
        .container{
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          overflow: hidden;
        }
          .card-box h3 a {
          
              font-size: 1.5em;
          
          }
          .text-secondary{
             font-size: 1.2em;
          }
          .place{
            font-size: 0.8em;
        }/* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }
@media all and (max-width: 1023px) and (min-width: 800px){ 
    .trainingschedule_img{
        width: 42vw;
          }
          .header_training_schedule>h2{
              font-size: 3.5em;
              margin-bottom: 2vh;
      
          }
          .img-fluid{
              width: 9vw;
             
          }
          .card-box{
              flex: 1;
          }
        .container{
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          overflow: hidden;
        }
          .card-box h3 a {
          
              font-size: 1.5em;
          
          }
          .text-secondary{
             font-size: 1.2em;
          }
          .place{
            font-size: 0.8em;
        }
    /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media all and (min-width: 1024px)  {

    .trainingschedule_img{
  width: 30vw;
    }
    .header_training_schedule>h2{
        font-size: 3.5em;
        margin-bottom: 2vh;

    }
    .img-fluid{
        width: 9vw;
       
    }
    .card-box{
        flex: 1;
    }
  .container{
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
  }
    .card-box h3 a {
    
        font-size: 1em;
    
    }
    .text-secondary{
       font-size: 1.2em;
    }
    .place{
        font-size: 0.8em;
    }
    
    /* big landscape tablets, laptops, and desktops */ }


.header_training_schedule{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 9vh;
}

.card-box {
    background-color: black;
    border: 1px solid #ddd;
    padding: 20px;
    box-shadow: 0px 0px 10px 0px #c5c5c5;
    margin-bottom: 30px;
    float: left;
    border-radius: 10px;
}
.card-box .card-thumbnail {
    max-height: 200px;
    overflow: hidden;
    border-radius: 10px;
    transition: 1s;
}

.card-box h3 a {
    display: flex;
    color: #D1C70B !important;
    text-decoration: none;
    align-items: center;
    flex-direction: column;
    
}
.card-thumbnail{
    display: flex;
    flex-direction: column;
    align-items: center;
   
}
.text-secondary{
    color: white !important;
}



.place .placeHeader{
    text-align: end;
}
.date{
    height: 30vh !important;
}
