
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital@1&display=swap');

@media all and (max-width: 319px) {   /* smartphones, iPhone, portrait 480x320 phones */ }
@media all and (max-width: 639px) and (min-width: 320px)  { 
  .row>* {
    margin-right: 1vh;
}
    .on_button{
      height: 8vh;

    }
    .on_button span {
        width: 100%;
       font-size: 0.8em;
       height:  0vh;
       margin-left: -4vh;
      }
      .iconbox{
 

        height: 7vh;
        margin-bottom: 0vh;
        margin-right: -2vh;
        }
    
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ }
@media all and (max-width: 799px) and (min-width: 640px) { 
    .on_button{
        height:  10vh;
   
    
    }
    .on_button span {
       font-size: 1em;
       height:  7vh;
      }
      .iconbox{
 

        height: 11vh;
    
    
        }
    
    /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }
@media all and (max-width: 1023px) and (min-width: 800px){ 
    
    /* tablet, landscape iPad, lo-res laptops ands desktops */ 
    .on_button{
        height: 11vh;
        font-size: 5vh;
    
    }
    .on_button span {
        font-size: 5vh;
        height:  7vh;
       }
       .iconbox{
 

        height: 11vh;
    
    
        }

}
@media all and (min-width: 1024px)  { 
    .on_button{
      
        height:10vh;
        font-size: 5vh;
    
     
    }
    .on_button span {
        font-size: 5vh;
        height:  7vh;
       }
    /* big landscape tablets, laptops, and desktops */ 
    .iconbox{
 

        height: 10vh;
    
    
        }

}

.on_button{
    font-family: 'Rubik', sans-serif;
    outline: none;
    border-radius: 48px;

     opacity: 1   ;
     cursor: pointer;
    background-color: #ffb510;
 
}
.on_button a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    align-content: center;
    flex-wrap: nowrap;
    text-decoration:none;
    

}
.on_button a span {
  display: flex;
  align-items: center;
    padding-right: 3vh;
color: white;

}
.on_button span {
    color: rgb(209, 199, 9);

    font-weight: 500;
    letter-spacing: 0.7px;
  }
  button:hover {
    animation: rotate 0.7s ease-in-out both;
  }
  button:hover span {
    animation: storm 0.7s ease-in-out both;
    animation-delay: 0.06s;
  }
  
  @keyframes rotate {
    0% {
      transform: rotate(0deg) translate3d(0, 0, 0);
    }
    25% {
      transform: rotate(3deg) translate3d(0, 0, 0);
    }
    50% {
      transform: rotate(-3deg) translate3d(0, 0, 0);
    }
    75% {
      transform: rotate(1deg) translate3d(0, 0, 0);
    }
    100% {
      transform: rotate(0deg) translate3d(0, 0, 0);
    }
  }
  @keyframes storm {
    0% {
      transform: translate3d(0, 0, 0) translateZ(0);
    }
    25% {
      transform: translate3d(4px, 0, 0) translateZ(0);
    }
    50% {
      transform: translate3d(-3px, 0, 0) translateZ(0);
    }
    75% {
      transform: translate3d(2px, 0, 0) translateZ(0);
    }
    100% {
      transform: translate3d(0, 0, 0) translateZ(0);
    }
  }
  