@media all and (max-width: 319px) {
  .input img {
    height: 25vh;
  } /* smartphones, iPhone, portrait 480x320 phones */
}
@media all and (max-width: 639px) and (min-width: 320px) {
  .input img {
    height: 20vh;
    width: 84vw;
  }
  .registration_text {
    font-size: 1.3em;
    width: 80vw;
    text-align: center;
  }
  .registration {
    height: 110vh;
    background-size: 70vh 40vh;
  } /* tablet, landscape iPad, lo-res laptops ands desktops */
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}
@media all and (max-width: 799px) and (min-width: 640px) {
  .input img {
    height: 25vh;
  }
  .registration_text {
    font-size: 1.3em;
  }
  .registration {
    height: 120vh;
  }
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
}
@media all and (max-width: 1023px) and (min-width: 800px) {
  .input img {
    height: 25vh;
  }
  .registration {
    width: 100%;
    height: 100%;
    background-size: 70vh 50vh;
  }

  .registration {
    height: 120vh;
  } /* tablet, landscape iPad, lo-res laptops ands desktops */
}
@media all and (min-width: 1024px) {
  .input img {
    height: 27vh;
  }
  .registration {
    height: 97vh;
  }
  /* big landscape tablets, laptops, and desktops */
}

.registration {
  background-image: url("../../data/image/registration/RegistrationBox.png");
  background-repeat: no-repeat;
  flex-direction: column;
  background-position: left bottom !important;

  color: black;
}
.registration .input {
  padding: 14vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.registration_text {
  margin-bottom: 5vh;
}
.modal-message {
  font-size: 18px;
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.modal-footer {
  justify-content: center;
}

.modal-footer button {
  background-color: #d1c709;
  border-color: #d1c709;
  color: #fff;
}
