a{
	text-decoration: none;
}

nav{
	display: flex;
    justify-content: space-between;
    min-height: 13vh;
    position: fixed;
    width: 100%;
    align-items: center;
    background-color: black;
    z-index: 10000;

}
.navlinks{
	display: flex;
	list-style: none;
	justify-content: space-around;
	width: 87%;
	font-size: 24px;
}
.link{
	letter-spacing: 3px;
	font-weight: bold;
}
.link a{
	color: white;
}
.link a:hover{
	color: red;
}
.logo{
	height: 10vh;
	display: flex;
}
.hum{
	cursor: pointer;
	display: none;
}

.icon_nav{
	display: none;
}
@media  (max-width:850px) {
	.icon_nav{
		display: flex;
	 
		position: absolute;
		left: 12vh;
		top: 3vh;
	
	}
	body{
		overflow-x: hidden;
	}
	.hum{
		display: block;
	}
	.navlinks{
		
		position: absolute;
		right: 0px;
		display: flex;
		flex-direction: column;
		height: 66vh;
    align-items: center;
    width: 100%;
    top: 10vh;
		background-color: black;
		font-size: 32px;
		transform: translateX(100%);

		transition: transform 0.5s ease-in ;


	}
	.active{
		transform: translateX(0%);
	}
}