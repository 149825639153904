
@media all and (max-width: 319px) {  
    .questions{
        height: 120vh;
    } /* smartphones, iPhone, portrait 480x320 phones */ }
@media all and (max-width: 639px) and (min-width: 320px)  {
    .questions{
        height: 120vh;
        
    } 
    .accordion {
        width: 90% !important;
    }
    
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ }
@media all and (max-width: 799px) and (min-width: 640px) { 
    .questions{
        height: 120vh;
    }
    .accordion {
        width: 90% !important;
    }
    /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }
@media all and (max-width: 1023px) and (min-width: 800px){
    .accordion {
        width: 90% !important;
    }
    
    
    /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media all and (min-width: 1024px)  {
    .accordion {
        width: 62% !important;
    }
    
    .questions{
        height: 120vh;
    }
    /* big landscape tablets, laptops, and desktops */ }


.questions{
    background-image:   linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('../../data/image/questions/2.jpeg'); 
    display: flex;
    flex-direction: column;
    align-items: center;
    background-attachment: fixed;
    background-size: 100vw 100%;
    background-repeat: no-repeat;

}
.questions >h1{
    color: white;

}

.accordion-button::after {
    margin-left: 0!important;
    margin-right: auto !important;
}
.elementor-widget{
    width: 100%;
    margin-top: 0rem;
    border-bottom: 4.5px solid #D1C70B;


}
.header_questions{
    color: white;
    padding: 10vh
}