
@media all and (max-width: 319px) {   /* smartphones, iPhone, portrait 480x320 phones */ }
@media all and (max-width: 639px) and (min-width: 320px)  { 
    
    .input_endsite {
        width: 70% !important;
    }
    .elementor-widget_end_site{
        width: 45%;
    }
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ }
@media all and (max-width: 799px) and (min-width: 640px) {
    .elementor-widget_end_site{
        width: 30%;
    }
    .input_endsite {
        width: 70% !important;
    }
    /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }
@media all and (max-width: 1023px) and (min-width: 800px){ 
    .elementor-widget_end_site{
        width: 30%;
    }
    
    .input_endsite {
        width: 68% !important;
    }
    /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media all and (min-width: 1024px)  { 
    
    .endsite_header{
       
        font-size: 2.5rem;
    }
    .elementor-widget_end_site{
        width: 25%;
    }
    /* big landscape tablets, laptops, and desktops */ 

}

.endsite{
    background-color: black;
  
}


.endsite_header{
    color: white;
    padding: 5vh;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.elementor-widget_end_site{
 
    margin-top: 0rem;
    border-bottom: 4.5px solid #D1C70B;
}
.input_endsite{
        width: 40%;
        border-radius: 20px;
        background-color: #1e2321;
        padding: 2vw;
}
.input_main{
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.maps{
    padding: 8vh;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.all_icons{
    display: flex;
    flex-direction: column;
    align-items: center;
}