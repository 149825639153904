@import url("https://fonts.googleapis.com/css2?family=Rubik:ital@1&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
}
html {
  width: 100% !important;

  margin: 0px;
  padding: 0px;
  font-family: "Rubik", sans-serif !important;
}
body {
  width: 100% !important;
  margin: 0px;
  padding: 0px;
  direction: rtl;
}
