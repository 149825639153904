footer {
  width: 100%;
  bottom: 0 !important;
  left: 0;
  position: relative;

  z-index: 100;
  background: black;
  background-image: url(../../data/image/logo/logo.jpeg);
  background-repeat: no-repeat;
  background-size: 25vh;
  background-position: 10vh;
}
.privacy {
  padding-right: 2vh;
}
.accessibilit p {
  font-size: 0.9em;
}
.accessibilit h2 {
  margin-top: 2rem;
}
footer .content {
  max-width: 1350px;
  margin: auto;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
footer .content p,
a {
  color: #fff;
}
footer .content .box {
  width: 33%;
  transition: all 0.4s ease;
}
footer .content .topic {
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 16px;
  display: flex;
}
footer .content p {
  text-align: justify;
}
footer .content .lower .topic {
  margin: 24px 0 5px 0;
}
footer .content .lower i {
  padding-right: 16px;
}
footer .content .middle {
  padding-left: 80px;
  padding-top: 26px;
}
footer .content .middle a {
  line-height: 32px;
}
footer .content .right input[type="text"] {
  height: 45px;
  width: 100%;
  outline: none;
  color: #d9d9d9;
  background: #000;
  border-radius: 5px;
  padding-left: 10px;
  font-size: 17px;
  border: 2px solid #222222;
}
footer .content .right input[type="submit"] {
  height: 42px;
  width: 100%;
  font-size: 18px;
  color: #d9d9d9;
  background: #eb2f06;
  outline: none;
  border-radius: 5px;
  letter-spacing: 1px;
  cursor: pointer;
  margin-top: 12px;
  border: 2px solid #eb2f06;
  transition: all 0.3s ease-in-out;
}
.content .right input[type="submit"]:hover {
  background: none;
  color: #eb2f06;
}
footer .content .media-icons a {
  font-size: 16px;
  height: 45px;
  width: 45px;
  display: inline-block;
  text-align: center;
  line-height: 43px;
  border-radius: 5px;
  margin: 30px 5px 0 0;
  transition: all 0.3s ease;
}
.content .media-icons a:hover {
  border-color: #eb2f06;
}
footer .bottom {
  width: 100%;
  text-align: right;
  color: #d9d9d9;
  display: flex;
  padding: 0 40px 5px 0;
  flex-direction: column;
  align-items: center;
}
footer .bottom a {
  color: #eb2f06;
}
footer a {
  transition: all 0.3s ease;
}
footer a:hover {
  color: rgb(209, 199, 11);
}
@media (max-width: 1100px) {
  footer .content .middle {
    padding-left: 50px;
  }
  footer {
    width: 100%;
    bottom: 0;
    left: 0;
    background: black;
    background-image: url(../../data/image/logo/logo.jpeg);
    background-repeat: no-repeat;
    background-size: 25vh;
    background-position: 10vh;
  }
}
@media (max-width: 950px) {
  footer .content .box {
    width: 50%;
  }
  .content .right {
    margin-top: 40px;
  }
  footer {
    width: 100%;
    bottom: 0;
    left: 0;
    background: black;
    background-image: url(../../data/image/logo/logo.jpeg);
    background-repeat: no-repeat;
    background-size: 19vh;
    background-position: 7vh;
  }
}
@media (max-width: 560px) {
  footer {
    position: relative;
  }
  footer .content .box {
    width: 100%;
    margin-top: 30px;
  }
  footer .content .middle {
    padding-left: 0;
  }

  footer {
    width: 100%;
    bottom: 0;
    left: 0;
    background: black;
    background-image: url(../../data/image/logo/logo.jpeg);
    background-repeat: no-repeat;
    background-size: 18vh;
    background-position: 1vh;
  }
}
