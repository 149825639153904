
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap');

@media all and (max-width: 319px) {  
    .about_us_text{
        font-size: 1.30em;
    } /* smartphones, iPhone, portrait 480x320 phones */ }
@media all and (max-width: 639px) and (min-width: 320px)  { 
    .about_us_text{
        font-size: 1.30em;
    }
    .aboutme{
        height: 130vh;

    }

.elementor-widget-container{

width: 40%;



}
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ }
@media all and (max-width: 799px) and (min-width: 640px) { 
    .about_us_text{
        font-size: 1.56em;
    }
    .aboutme{
        height: 130vh;

    }

.elementor-widget-container{

    width: 28%;
    
    
    
    }
    /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }
@media all and (max-width: 1023px) and (min-width: 800px){ 

    .about_us_text{
        font-size: 1.56em;
    }
    .aboutme{
        height: 130vh;

    }
    
.elementor-widget-container{

    width: 23%;
    
    
    
    }
    
    /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media all and (min-width: 1024px)  { 
    
    .about_us_text{
        font-size: 1.56em;
    }
    .aboutme{
        height: 100vh;

    }
    .elementor-widget-container{

        width: 15%;
        
        
        
        }
        
    
    /* big landscape tablets, laptops, and desktops */ }

.aboutme{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
   
    box-sizing: border-box;
    width: 100%;
    padding: 2% 5%;
    background-image:  linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1) ),url('../../data/image/aboutus/2.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 10% 30% ;


}
.elementor-heading-title{
    color: white;
margin-top: 2rem;
font-family: 'Rubik', sans-serif;
font-size: 45px;
font-weight: 900;

}


.elementor-widget-container{

    margin-top: 0.5vh;
    border-bottom: 4.5px solid #D1C70B;



}
.about_us_text{
    padding-top: 4vh;
    color: white;
    font-family: 'Rubik', sans-serif;
    word-wrap: break-word;
    overflow-wrap: break-word;
}
