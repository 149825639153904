@media all and (max-width: 319px) {  
  .startnow_main{
    height: 190vh;
  } /* smartphones, iPhone, portrait 480x320 phones */ }
@media all and (max-width: 639px) and (min-width: 320px)  { 
  .startnow_main{
    height: 190vh;
  }/* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ }
@media all and (max-width: 799px) and (min-width: 640px) { 
  .startnow_main{
    height: 170vh;
  }
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }
@media all and (max-width: 1023px) and (min-width: 800px){
  .startnow_main{
    height: 120vh;
  }
   /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media all and (min-width: 1024px)  {
    
    .toggle-btn{
  
        padding: 2vw;
        font-size: 2em;
     }
    .img-startnow_main{
        width: 30vw;

    }
    .startnow_main{
      height: 120vh;
    }/* big landscape tablets, laptops, and desktops */ }

.startnow_main{

    display: flex;
    background-color: black;
    flex-direction: column;
    align-items: center;
}

.header_startnow{
    display: flex;
    margin-top: 10vh;
    flex-direction: column;
    align-items: center;

}

.packages {
    margin: 20px;
    width: 300px;
    padding-bottom: 1.5em;
    height: 100%;
    background-color: #1e2321;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: 20px;
    box-shadow: 0 19px 38px rgba(30, 35, 33, 1), 0 15px 12px rgba(30, 35, 33, 0.2);
    flex-wrap: wrap;
    color: #f4f4f4;
  }

  .rt-container h1,
  h2 {
    font-size: 2.2em;
  }
  .text1,
  .text2{
      color: #fff;
  }
  
  .list p {
    font-size: 1.3em;

    color: black;

    padding: 1vh;
  }
  
  .first {
    margin-top: 40px;
    border-top: 1px solid #f4f4f4;
  }
  
  .list {
    display: flex;
    width: 100%;
    margin-top: 2vw;
    background-color: #DFE0DF;
    flex-direction: column;
    align-items: center;



  }
  
  ol,
  ul {
    padding: 0;
  }
  
  .top {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  input,
  label {
    display: inline-block;
    vertical-align: middle;
    margin: 10px 0;
  }
  
  .button {
    padding: 10px 30px;
    text-decoration: none;
    font-size: 1.4em;
    margin: 15px 15px;
    border-radius: 50px;
    color: #f4f4f4;
    transition: all 0.3s ease 0s;
  }
  
  .button:hover {
    transform: scale(1.2);
  }
  
  .button1 {
    background-color: #FFB355;
    
  }
  
  .button2 {
    background-color: #4B8178;
    box-shadow: 0 0 10px 0 #ff007c inset, 0 0 20px 2px #ff007c;
  }
  
  .button3 {
    background-color: #FFB355;
  
  }
  .switch_phone{
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  .switch_phone input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #1e2321;
    -webkit-transition: 0.4s;
  
    box-shadow: 2px 6px 25px #1e2321;
    transform: translate(0px, 0px);
    transition: 0.6s ease transform, 0.6s box-shadow;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  
  input:checked + .slider {
    background-color: #FFB355;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #FFB355;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  
  .package-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
 .toggle-btn{
    color: white;

 }
 .packages >h2{
    margin-top: 2vh;
 }
 .packages >h1{
    margin-top: 2vh;
 }