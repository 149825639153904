

@media all and (max-width: 319px) {  
    .main_secondary{
        margin-right: 5vh;
          display: flex !important;
      flex-direction:column-reverse !important;
      justify-content: center !important;
      align-items: flex-start !important;
      }
      .main_secondary_video{
          height:  50vh !important;
          width: 80vw;
          margin-bottom: 3vh;
      }
      .suitsme_main{
          background-size: 50vw;
          height: 250vh;
      }
      .suitsme_header{
          width: 70vw;
          padding: 3vh;
      }
  
      .main_text{
          font-size: 1.3em;
          padding: 3vw;
      }
      .check{
          font-size: 1.0em;
      }
      .check_last_title{
          font-size: 1.1em;
          color: white;
          align-items: center;
      }
      .includ_text{
        
          padding: 3vh;
      }
      .header_includ{
        font-size: 1.2em;
          margin-bottom: 5vh;
      }
      .ember{
        font-size: 1.2em;
      }
      .elementor-widget-header_includ {
          width: 40vw;
          margin-top: 0.4vw;
      }
    /* smartphones, iPhone, portrait 480x320 phones */ }
@media all and (max-width: 639px) and (min-width: 320px)  { 

    .main_secondary{
        margin-right: 5vh;
          display: flex !important;
      flex-direction:column-reverse !important;
      justify-content: center !important;
      align-items: flex-start !important;

      }
      .main_secondary_video{
          height:  50vh !important;
          width: 80vw;
          margin-bottom: 3vh;
      }
      .suitsme_main{
          background-size: 23vh;
          height: 315vh ;
      }
      .suitsme_header{
          width: 70vw;
          padding: 3vh;
      }
  
      .main_text{
          font-size: 1.3em;
          padding: 3vw;
      }
      .check{
          font-size: 1.0em;
      }
      .check_last_title{
          font-size: 1.1em;
          color: white;
          align-items: center;
      }
      .includ_text{
        
          padding: 3vh;
      }
      .header_includ{
        font-size: 1.2em;
          margin-bottom: 5vh;
      }
      .ember{
        font-size: 1.2em;
      }
      .elementor-widget-header_includ {
          width: 40vw;
          margin-top: 0.4vw;
      }
  
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ }
@media all and (max-width: 799px) and (min-width: 640px) { 
    .main_secondary{
      margin-right: 5vh;
        display: flex !important;
    flex-direction:column-reverse !important;
    justify-content: center !important;
    align-items: flex-start !important;
    }
    .main_secondary_video{
        height:  50vh !important;
        width: 80vw;
        margin-bottom: 3vh;
    }
    .suitsme_main{
        background-size: 30vw;
        height: 290vh;
    }
    .suitsme_header{
        width: 43vw;
    }

    .main_text{
        font-size: 1.6em;
        padding: 3vw;
    }
    .check{
        font-size: 1.4em;
    }
    .check_last_title{
        font-size: 1.6em;
        color: white;
        align-items: center;
    }
    .includ_text{
      
        padding: 3vh;
    }
    .header_includ{
        font-size: 1.3em;
        margin-bottom: 5vh;
    }
    .ember{
        font-size: 1.3em;
    }
    .elementor-widget-header_includ {
        width: 23vw;
        margin-top: 0.4vw;

    }/* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }
@media all and (max-width: 1023px) and (min-width: 800px){ 
    .suitsme_main{
        background-size: 30vw;
        height: 260vh;
    }
    .suitsme_header{
        width: 43vw;
    }

    .main_text{
        font-size: 1.8em;
        padding: 4vw;
    }
    .check{
        font-size: 1.2em;
    }
    .check_last_title{
        font-size: 1.8em;
        color: white;
        align-items: center;
    }
    .includ_text{
      
        padding: 3vh;
    }
    .header_includ{
        font-size: 1.8em;
        margin-bottom: 5vh;
    }
    .ember{
        font-size: 1.6em;
    }
    .elementor-widget-header_includ {
        width: 23vw;
        margin-top: 0.4vw;

    }
    
    /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media all and (min-width: 1024px)  {
    .suitsme_main{
        background-size: 20vw;
        height: 197vh;
    }
    .suitsme_header{
        width: 30vw;
    }

    .main_text{
        font-size: 2em;
        padding: 4vw;
    }
    .check{
        font-size: 1.4em;
    }
    .check_last_title{
        font-size: 2em;
        color: white;
        align-items: center;
    }
    .includ_text{
      
        padding: 3vh;
    }
    .header_includ{
        font-size: 2em;
        margin-bottom: 5vh;
    }
    .ember{
        font-size: 1.4em;
    }
    .elementor-widget-header_includ {
        width: 16vw;
        margin-top: 0.4vw;

    }
    
    /* big landscape tablets, laptops, and desktops */ }


.suitsme_main{
    background-color: black;
    background-image: url('../../data/image/suitsme/2.png');
    background-repeat: no-repeat;
    background-position: bottom left;

}
.suitsme{
    display: flex;
    align-items: center;
    flex-direction: column;
   

}

.main_text{
    color: white;
}
.main_secondary{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;


}
.check{
    color: white;

}
.check_last_title{
    display: flex;
    align-items: center;
    flex-direction: column;

}
.includ_text{
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;


}

.elementor-widget-header_includ {

    border-bottom: 4.5px solid #D1C70B;
}

