.thanks {
  height: 100vh !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 37vw;
  background-color: black;
}
.thanks {
  padding: 20px;
}

.thanks h1 {
  color: #f4ef16;
  font-size: 40px;
  text-align: center;
}
.thanks h2 {
  padding: 3rem;
  color: #f4ef16;
  font-size: 30px;
  text-align: center;
}
