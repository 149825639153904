@import url("https://fonts.googleapis.com/css2?family=Rubik:ital@1&display=swap");

.page {
  font-family: "Rubik", sans-serif !important;

  width: 100% !important;
}
body::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}
body::-webkit-scrollbar-track {
  background: rgb(0, 0, 0); /* color of the tracking area */
}
body::-webkit-scrollbar-thumb {
  background-color: #f4ef16; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}
